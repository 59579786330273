<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select
        v-model="listQuery.storeId"
        placeholder="门店"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in []"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select
        v-model="listQuery.typeId"
        placeholder="设备类别"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in typeOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <div style="margin-bottom: 5px">报修数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="order_num"
        label="订单编号"
        width="200"
      ></el-table-column>
      <el-table-column label="名称" width="180" align="center" prop="name">
        <template slot-scope="scope">
          {{ scope.row.device_type && scope.row.device_type.name }}
        </template>
      </el-table-column>
      <el-table-column label="省/市/区/街道" width="220" prop="name">
        <template slot-scope="scope">
          {{ scope.row.province ? scope.row.province.name : "无" }} /
          {{ scope.row.city ? scope.row.city.name : "无" }} /
          {{ scope.row.area ? scope.row.area.name : "无" }} /
          {{ scope.row.street ? scope.row.street.name : "无" }}
        </template>
      </el-table-column>
      <el-table-column label="报修标签" width="180" align="center" prop="name">
        <template slot-scope="scope">
          {{ scope.row.tag }}
        </template>
      </el-table-column>
      <el-table-column label="客户留言" align="center" prop="name">
        <template slot-scope="scope">
          {{ scope.row.remark }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        label="操作"-->
      <!--        align="center"-->
      <!--        class-name="small-padding fixed-width"-->
      <!--      >-->
      <!--      </el-table-column>-->
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import { DIALOG_TITLE_TYPE } from "@/utils/enumUtile";
import { formatApiDate } from "@/utils/utils";
import { getEquipmentList, getReportrepairList } from "@/api/equipment";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        typeId: "",
        storeId: "",
        tag: "",
        status: ""
      },
      typeOption: []
    };
  },
  created() {
    this.getList();
    this.getTypeOption();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备类型
     */
    getTypeOption() {
      const params = {
        page: 1,
        limit: 9999
      };
      getEquipmentList(params).then(resp => {
        this.typeOption = resp.data.data;
      });
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getReportrepairList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    }
  }
};
</script>
<style scoped lang="scss"></style>
